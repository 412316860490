import React from 'react'

import Matches from '@components/matches'
import Statistics from '@components/statistics'
import Layout from '@components/layout'
import SEO from '@components/seo'

const IndexPage = () => {
  const title = 'Ottelut'

  return (
    <Layout>
      <SEO title={title} />
      <Matches title={title} />
      <Statistics />
    </Layout>
  )
}

export default IndexPage
